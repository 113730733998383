import { layout, view } from '@/core/Router'

const routes = [
  layout('public', '/', [
    view(
      'checkout',
      '/',
      'checkout/Index'
    ),
    view(
      'login',
      '/register',
      'auth/Register'
    ),
    view(
      'login',
      '/login',
      'auth/Login'
    ),
    view(
      'forgot-password',
      '/forgot-password',
      'auth/Forgot'
    ),
    view(
      'reset-password',
      '/password/reset/:token',
      'auth/Reset'
    ),
    
  ]),
  layout('dashboard', '/dashboard', [
    view(
      'dashboard',
      '/',
      'dashboard/Dashboard',
      { requireAuth: true }
    ),
  ])
]

export default routes
