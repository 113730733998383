import Vue from 'vue'
import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
  keyName: 'meta',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})
