import { lowerCase } from 'lodash'

/**
 * Router Class
 * @class
 * @since 1.0.0
 * @author Brayan Rincon <hi@bracodev.com>
 */
export default class Router {
  /**
   * Layout component
   *
   * @param {String} layout Layout name
   * @param {Object} children Childrens
   * @param {String} path Path url
   * @returns {Object} Layout component
   */
  layout (layout = 'Default', path, children) {
    const dir = lowerCase(layout)

    return {
      children,
      component: () => import(
        /* webpackChunkName: "layout-[request]" */
        `@/layouts/${dir}/Index`
      ),
      path
    }
  }

  /**
   * Get view route
   *
   * @param {String} name View name
   * @param {Object} component Component
   * @param {String} path Route path
   * @param {Boolean} auth Authorize requered
   * @param {Object} meta Metadata
   * @param {Object} props Props
   *
   * @returns {Object}
   */
  component (name, path = '', component, meta = {}, props = {}) {
    const _component = { default: component.name }
    const _componentPath = component.path ? `${component.path}/` : ''
    const components = {}

    for (const [key, value] of Object.entries(_component)) {
      components[key] = () => import(
        `@/views/${_componentPath}${value}`
      )
    }

    return {
      name,
      path,
      components,
      props,
      meta
    }
  }

  /**
   * Create a new Route View
   * @function routeView
   * @public
   * @since 2.0.1
   * @author Brayan Rincon <hi@bracodev.com>
   *
   * @param {String} name Route name
   * @param {String} path Route path
   * @param {Object} view Component and attributes
   * @param {Object} meta Adicional metadata
   * @param {Object} props Props
   *
   * @returns {Object} Route View
   */
  view (name, path, view, meta = {}, props = {}) {
    const components = {}

    components.default = () => import(
      `@/views/${view}`
    )

    return {
      name,
      path,
      components,
      props,
      meta
    }
  }

  /**
   *
   * @param {Number} code Error code
   * @returns
   */
  error (code = 404) {
    return import(
      /* webpackChunkName: "error-[request]" */
      `@/views/errors/${code}.vue`
    )
  }

  /**
   *
   * @returns
   */
  notFound () {
    return {
      name: 'NotFound',
      alias: '*',
      path: '/404',
      component: () => this.error(404)
    }
  }

  /**
   * Not found in eviromenet
   * @param {String} eviroment Eviroment
   * @param {String} alias Alias
   * @returns
   */
  notFoundInEviroment (eviroment, alias = '*') {
    return {
      name: 'NotFound',
      alias: alias,
      path: eviroment + '/404',
      component: () => this.error(404)
    }
  }

  /**
   * Abort
   * @param {Number} code Error code
   * @returns
   */
  abort (code = 404) {
    return {
      name: 'NotFound',
      path: '*',
      component: () => this.error(code)
    }
  }

  scrollBehavior (to, from, savedPosition) {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition
    return { x: 0, y: 0 }
  }
}
