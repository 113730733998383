const COLORS = {
  primary: '#30a687',
  secondary: '#0097A7',
  accent: '#00ACC1',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107'
}

export default COLORS
