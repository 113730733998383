import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import conf from '@/config'
import es from 'vuetify/lib/locale/es'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: conf.colors.primary,
        secondary: conf.colors.secondary,
        accent: conf.colors.accent,
        error: conf.colors.error,
        info: conf.colors.info,
        success: conf.colors.success,
        warning: conf.colors.warning
      }
    }
  },
  lang: {
    locales: { es },
    current: 'es'
  }
})
