import HttpClient from "@/core/Http/HttpClient";

export default class Service {
  _service = "";
  _base = "";
  httpClient;

  constructor(service = "", base = "") {
    this._service = service;
    this._base = base;
    this.httpClient = new HttpClient();
    this.api = this.httpClient.http;
  }

  csrfCookie () {
    return new Promise((resolve, reject) => {
      this.httpClient.httpCSRF
        .get('/sanctum/csrf-cookie')
        .then(resp => resolve(resp))
        .catch(error => reject(error))
    })
  }

  async all() {
    return await this.httpClient
      .get(`${this._base}/${this._service}`)
      .then((response) => {
        return response.data.values;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  async show(id) {
    return await this.httpClient
      .get(`${this._base}/${this._service}/${id}`)
      .then((response) => {
        return response.data.values;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  async request(url, URLParams = {}, method = "get", data = {}, headers = {}) {
    const p = this.createParamsURL(URLParams);
    const config = {
      method: method,
      url: `${this._base}/${url}${p}`,
      data: data,
      headers: headers,
    };
    return await this.httpClient(config)
      .then((response) => {
        return Promise.resolve(response.data.values);
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  /**
   *
   * @param {Object} params
   * @returns
   */
  createParamsURL(params) {
    let _params = "";

    if (params != null) {
      _params = "?";
      for (const [key, value] of Object.entries(params)) {
        if (value) _params += `${key}=${value}&`;
      }
    }

    return _params.substring(0, _params.length - 1);
  }

  async store(data) {
    return await this.httpClient
      .post(`${this._base}/${this._service}`, data)
      .then((response) => {
        return response.data.values;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  async update(data) {
    return await this.httpClient
      .post(`${this._base}/${this._service}/${data.id}`, data)
      .then((response) => {
        return response.data.values;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }
}
