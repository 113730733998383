import Service from "./Service";

export default class Authenticable extends Service {
  /**
   *
   */
  login(credentials) {
    return new Promise((resolve, reject) => {
      this.csrfCookie().then(() => {
        this.api
          .post(`${this._service}/login`, credentials)
          .then((response) => {
            if (response.data.accessToken) {
              const auth = {
                status: true,
                accessToken: response.data.accessToken,
                tokenType: response.data.tokenType,
                user: response.data.user,
                customer: response.data.customer,
              };

              let aux = {
                customer_id: '',
                brand: '',
                default: '',
                exp_month: '',
                exp_year: '',
                is_eu_card: '',
                workflow: '',
                last_4: '',
                stripe_pm_id: '',
                type: '',
                show: false,
              }


              localStorage.setItem("auth", JSON.stringify(auth));
              if(response.data.method == null){
                localStorage.setItem("stripe_method_db", JSON.stringify(aux));
              }else{
                localStorage.setItem("stripe_method_db", JSON.stringify(response.data.method));
              }
              aux = null
              this.httpClient.addHeader(
                "Authorization",
                `${auth.tokenType} ${auth.accessToken}`
              );

              this.makeInterceptors();

              resolve(auth);
            } else {
              reject(new Error("Access denied"));
            }
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    });
  }




  logout() {
    return new Promise((resolve, reject) => {
      this.api
        .post(`${this._service}/logout`)
        .then((response) => {
          localStorage.clear();
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  forgotPassword(email) {
    email = {email: email}
    return new Promise((resolve, reject) => {
      this.api
        .post(`${this._service}/forgot-password`, email)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }


  registercustomer(data) {
    data = {data: data}
    return new Promise((resolve, reject) => {
      this.api
        .post(`${this._service}/registercustomer`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  resetpassword(data) {
    data = {data: data}
    return new Promise((resolve, reject) => {
      this.api
        .post(`${this._service}/resetpassword`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  resetPassword(email) {
    email = {email: email}
    return new Promise((resolve, reject) => {
      this.api
        .post(`${this._service}/forgot-password`, email)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }


  updateProfile(user) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post("vendors/profile", user)
        .then((response) => {
          resolve(response.data.values);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  updatePassword(password) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post("vendors/security", password)
        .then((response) => {
          resolve(response.data.values);
        })
        .catch((error) => {
          reject(error.response);
        });
    });




  }

   saveorder(data) {
    data = {data: data}
    return new Promise((resolve, reject) => {
      this.api
        .post(`${this._service}/saveorder`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

   getapp(data) {
    data = {data: data}
    return new Promise((resolve, reject) => {
      this.api
        .post(`${this._service}/getapp`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  validatesubdomain(data) {
    data = {data: data}
    return new Promise((resolve, reject) => {
      this.api
        .post(`${this._service}/validatesubdomain`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  savepaymentmethod(data) {
    data = {data: data}
    return new Promise((resolve, reject) => {
      this.api
        .post(`${this._service}/savepaymentmethod`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  webhoock(data) {
    data = {data: data}
    return new Promise((resolve, reject) => {
      this.api
        .post(`${this._service}/webhoock`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }
   getdata(data) {
    var  customer = null
     var auth  = JSON.parse(localStorage.getItem('auth'))
     if(auth != null){
      customer = auth.customer.id
     }



    return new Promise((resolve, reject) => {
      this.api
        .get(`${this._service}/getdata?url=`+data + '&customer=' + customer)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }


  updateclient(data) {
    data = {data: data}
    return new Promise((resolve, reject) => {
      this.api
        .post(`${this._service}/update-client`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  initAccessToken() {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth && auth.accessToken) {
      this.httpClient.defaults.headers.common.Authorization = `${auth.tokenType} ${auth.accessToken}`;
    }
    return false;
  }

  isAuth() {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth && auth.accessToken) {
      return true;
    }
    return false;
  }

  auth() {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth && auth.accessToken) {
      return auth;
    }
    return false;
  }

  refreshToken(data) {
    const auth = {
      status: true,
      accessToken: data.accessToken,
      tokenType: data.tokenType,
      user: data.user,
    };

    localStorage.setItem("auth", JSON.stringify(auth));

    this.httpClient.defaults.headers.common.Authorization = `${auth.tokenType} ${auth.accessToken}`;

    return auth;
  }

  makeInterceptors() {
    this.api.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {
        const originalConfig = err.config;
        if (originalConfig.url !== `${this._service}/login` && err.response) {
          // Access Token was expired
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            try {
              const rs = await this.api.post(`${this._service}/refresh-token`, {
                refreshToken: this.auth().accessToken,
              });
              const { accessToken } = rs.data;
              // store.dispatch('agents/refreshToken', accessToken)
              this.refreshToken(accessToken);
              return this.api(originalConfig);
            } catch (_error) {
              return Promise.reject(_error);
            }
          }
        }
        return Promise.reject(err);
      }
    );
  }

  getUser() {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth && auth.accessToken) {
      return auth.user;
    }
    return null;
  }

  authHeader() {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth && auth.accessToken) {
      return { Authorization: `${auth.tokenType}: ${auth.accessToken}` };
    } else {
      return {};
    }
  }
}
