import CONFIG from '@/config'
import { LocalStorage as ls } from '@/core/LocalStorage'

const DEFAULT_LANG = CONFIG.locale.lang

const state = {
  name: '',
  version: '',
  cacheVersion: '',
  lang: DEFAULT_LANG,
  cacheCreated: new Date()
}

const getters = {
  version (state) {
    return state.version
  },
  lang (state) {
    return state ? state.lang : ''
  }
}

const actions = {
  init (context) {
    context.commit('INIT_STORE')
  },

  auth (context) {
    const data = context.state.service.auth()
    if (data) { context.commit('LOGIN', data) } else { context.commit('LOGOUT') }
  },

  clear (context) {
    console.log('clear store')
    context.commit('INIT_STORE')
  },

  changeLang (context, lang) {
    context.commit('CHANGE_LANG', lang)
  }
}

const mutations = {
  INIT_STORE (state) {
    // const currentLang = state.lang
    const storeLS = ls.get(CONFIG.cache.name)

    if (storeLS) {
      state.lang = storeLS.lang
      state.cacheCreated = storeLS.cacheCreated
      state.cacheVersion = storeLS.cacheVersion
    } else {
      state.lang = DEFAULT_LANG
      ls.set(CONFIG.cache.name, state)
    }
  },

  CLEAR_STORE () {
    ls.clear()
  },

  CHANGE_LANG (state, payload) {
    state.lang = payload
    ls.set(CONFIG.cache.name, state)
  }
}

export default { namespaced: true, state, getters, actions, mutations }
