<template>
  <div>
    <div class="card summary py-2">
      <h5 class="mt-0">{{ $t("Summary") }}</h5>
      <ul class="summary-detail fs-12 icon-gray pl-0">
        <li>
          <i class="fas fa-home mr-2"></i> {{ $t("Home cleaning") }}
          <ul>
            <li>{{ data.bedrooms }} {{ $t("bedrooms") }}</li>
            <li>{{ data.bathrooms }} {{ $t("bathrooms") }}</li>
          </ul>
        </li>
        <li>
          <i class="fas fa-clock mr-2"></i> {{ data.duration }} {{ $t("hour of cleaning") }}
        </li>
        <li><i class="fas fa-refresh mr-2"></i> {{ $t(frecuencyText) }}</li>
        <li>
          <i class="fas fa-calendar mr-2"></i>
          <span class="text-uppercase">
            {{ formatDate(data.date) }}
            <!-- {{ date }} -->
          </span>
            {{ data.time }}
        </li>
        <li>
          <i class="fa-solid fa-paw mr-2"></i>
          <p>
            <span>Pets</span>
          </p>
          <span
            class="badge bg-limpio mr-1"
            v-for="(item, index) in data.pets"
            :key="index"
            >{{ $t(item.name) }}</span
          >
        </li>
        <li>
          <i class="fa-solid fa-broom mr-1"> </i>
          <p v-if="data.extras.length > 0">
            <span>Extras</span>
          </p>
          <span
            class="badge bg-limpio mr-1"
            v-for="(item, index) in data.extras"
            :key="index"
            >{{ $t(item.name) }}</span
          >
        </li>
      </ul>
      <div
        class="card-footer py-2 px-0 d-flex justify-content-around border-top"
      >
        <strong class="fs-14">{{ $t("Your price starts at") }}</strong>
        <span
          >EUR <strong>{{ data.total }}</strong>
        </span>
      </div>
    </div>
    <!-- 
    <span>
      <i class="fa fa-exclamation-circle mr-2 text-primary"></i>
      {{ $t("The price will depended on the selection of cleaners available") }}
    </span>
    -->
    <button
      :disabled="!stepStatus"
      ref="btnContinueInfo"
      class="btn btn-primary btn-block fs-14 pull-right mt-3"
      type="button"
      @click="$emit('change')"
    >
      {{ infoButton }}
    </button>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "SummaryCard",
  data: () => {
    return {
      step: null
    };
  },
  props: {
    total: Number,
    date: String,
    time: String,
    data: Object,
    stepStatus: Boolean,
    infoButton : String,
    userLogin: Object
    
  },
  
  computed: {
    frecuencyText() {
      switch (this.data.frecuency) {
        case "1":
          return "Weekly";
        case "2":
          return "Bi-Weekly";
        case "3":
          return "One Time";
        default:
          return "Weekly";
      }
    },
  },

  methods: {
  formatDate(date) {
      
      if (date) {
        return moment(date).format("dddd, MMMM D -");
      }
    },
  }
};
</script>
