<template>
  <v-item v-slot="{ active, toggle }" @change="change(item.id)">
    <v-card
      :color="active ? 'primary' : ''"
      class="d-flex align-center"
      height="60"
      @click="toggle"
    >
      <v-scroll-y-transition>
        <div
          v-if="active"
          class="text-h6 flex-grow-1 text-center white--text"
        >
          {{ item.text }}
        </div>
        <div v-else class="text-h6 flex-grow-1 text-center">
          {{ item.text }}
        </div>
      </v-scroll-y-transition>
    </v-card>
  </v-item>
</template>
<script>
export default {
  name: "ChoiseItemGroup",
  data: () => {
    return {}
  },
  props: {
    item: Object,
    height: {
      type: [String, Number],
      default: '50'
    }
  },
  methods: {
    change (e) {
      console.log('ChoiseItemGroup', e);
      this.$emit('change', e)
    }
  }
}
</script>
