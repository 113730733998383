export const CURRENCY = process.env.VUE_APP_DEFAULT_CURRENCY || 'usd'

export const LOCALE = process.env.VUE_APP_I18N_LOCALE || 'en'

export const FALLBACK_LOCALE = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en'

export const SUPPORT_LOCALES = [
  { id: 'es', text: 'Spanish', code: 'es' },
  { id: 'en', text: 'English', code: 'en' },
  { id: 'de', text: 'German', code: 'de' }
]

export const SUPPORT_CURRENCY = [
  { id: 'usd', text: 'USD' }
]
