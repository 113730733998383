import CONFIG from '@/config'
import axios from 'axios'

const Axios = axios.create(CONFIG.http)
const AxiosCSRF = axios.create(CONFIG.http)

export default class HttpClient {
  #http = Axios;
  #httpCSRF = AxiosCSRF;

  get http () {
    console.log(this.#http)
    return this.#http
  }

  get httpCSRF () {
    return this.#httpCSRF
  }

  constructor () {
    this.#http.defaults.baseURL = `${CONFIG.apiURL}/${CONFIG.apiPrefix}/${CONFIG.apiVersion}`
    this.#httpCSRF.defaults.baseURL = `${CONFIG.apiURL}`
  }


  /**
   * Add header to Axios interceptor
   * @param {String} key Header
   * @param {*} value
   */
  addHeader (key, value) {
    this.#http.interceptors.request.use(
      function (config) {
        config.headers[key] = value
        return config
      },
      function (err) {
        return Promise.reject(err)
      }
    )
  }
}
