<template>
  <button
    :type="type"
    class="btn btn-primary btn-round mb-3"
    :class="loading ? 'button button--loading' : ''"
    v-bind="$attrs"
  >
    <span class="button__text"><slot /></span>
  </button>
</template>

<script>
export default {
  name: "BlueButton",
  props: {
    type: {
      type: String,
      default: "button",
    },
    loading :{
      type: Boolean,
      default: false
    }
  },
};
</script>

<style lang="css" scoped>

.button {
  position: relative;
}

button:disabled,
button[disabled]{
  background-color: #cccccc;
  color: #666666;
}


.button__text {
  transition: all 0.2s;
}

.button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
</style>
