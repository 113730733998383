import CONFIG from '@/config'
import moment from 'moment'
import { notice } from '@/plugins/vue-toastification'
import { slug } from '@/core/Helpers'

const MIXINS = {

  data: () => ({

  }),

  computed: {
    config: () => CONFIG,
    appURL: () => CONFIG.appURL
  },

  methods: {
    l (...args) {
      console.log(...args)
    },

    moment: function () {
      return moment()
    },

    dateFormat: function (date, format) {
      return moment(date).format(format)
    },

    goto (url, params) {
      this.$router.push({ path: url, query: params }).catch(()=>{})
    },

    goBack () {
      this.$router.go(-1)
    },

    notify (message, type = 'success', timeout = 5000) {
      const options = {
        type: type,
        timeout: timeout,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: true,
        hideProgressBar: false,
        closeButton: 'button',
        icon: true,
        rtl: false
      }
      this.$toast(message, options)
    },

    catchRequest (error) {
      console.log(error)
      const self = this

      if (error.status === 422 && error.data.errors) {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(error.data.errors)) {
          self.notify(value[0], 'error')
        }
      } else {
        self.notify(error.data.message, 'error')
      }
    },

    openWindow () {
      window.open(this.url, '_blank')
    },

    notice,

    slug

  }
}

export default MIXINS
