/**
 * Generate an alpanumeric hash with special characters
 * @param {Number} length Length of the hash to be generated
 * @returns
 */
export function hash (length = 15) {
  let _hash = ''

  const NUMERIC = '0123456789'
  const ALPHABETIC_UPPER = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const ALPHABETIC_LOWER = 'abcdefghijklmnopqrstuvwxyz'
  const SPECIALS = ',.!@#$%^&*'
  const BOOTH = NUMERIC + ALPHABETIC_UPPER + ALPHABETIC_LOWER + SPECIALS

  for (let i = 1; i <= length; i++) {
    const char = Math.floor(Math.random() * BOOTH.length + 1)
    _hash += BOOTH.charAt(char)
  }

  return _hash
}

/**
 * Universally unique identifier
 * @returns {String}
 */
export function UUID () {
  let dt = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
  return uuid
}

/**
 * Checks if a string starts with a slash, if not adds it
 * @param {String} str
 * @returns
 */
export function leadingSlash (str) {
  return str.startsWith('/') ? str : '/' + str
}

/**
 * Check if a string ends in slash, if not add it
 * @param {String} str
 * @returns
 */
export function trailingSlash (str) {
  return str.endsWith('/') ? str : str + '/'
}

/**
 *
 * @param  {...any} arr
 * @returns
 */
export function merge (...arr) {
  return arr.reduce((acc, cur) => {
    for (const key in cur) {
      acc[key] = cur[key]
    }
    return acc
  }, {})
}

export function slug (text) {
  if (text === '') return ''
  let slug = ''
  // Change to lower case
  const titleLower = text.toLowerCase()
  // Letter "e"
  slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
  // Letter "a"
  slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
  // Letter "o"
  slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
  // Letter "u"
  slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
  // Letter "d"
  slug = slug.replace(/đ/gi, 'd')
  // Trim the last whitespace
  slug = slug.replace(/\s*$/g, '')
  // Change whitespace to "-"
  slug = slug.replace(/\s+/g, '-')

  return slug
}
