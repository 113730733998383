import CONFIG from '@/config'
import Vue from 'vue'

const moment = require('moment')

moment.locale(CONFIG.locale.lang)

Vue.use(require('vue-moment'), {
  moment
})

export default moment
