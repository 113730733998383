import * as modules from './modules'

// import AppSettingsPlugin from './AppSettingsPlugin'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  // plugins: [AppSettingsPlugin],
  modules
})

export default store

export const ROOT_DISPATCH = Object.freeze({ root: true })
