<template>
  <div>
    


   <div v-if="preloader == true" style="z-index: 100000; position: absolute; background: white!important; width: 100%; height: 100%;">
    
    </div>
      
  <v-app>
    <router-view />
  </v-app>

  </div>
</template>
<script>
import CONFIG from './config'
export default {
  name: 'App',
  meta: {
    title: '',
    titleTemplate: `%s | ${CONFIG.name}`,
    htmlAttrs: { lang: CONFIG.locale.lang },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: CONFIG.description }
    ]
  },
  data :()=> ({
    preloader : true
  }),
  mounted() {
    this.$store.dispatch('app/init')
    this.getInit()
  },
  methods: {
     getInit(){
        this.preloader= true
         var url = window.location.origin

          this.$store
            .dispatch("users/validatesubdomain", url)
            .then((response) => {
              
              if(response.data.status == 200){
                if(response.data.agency == null){
                  this.preloader= true
                  window.location.href = "HTTPS://getlimpio.co"
                }
              }
             
            })
            .catch((error) => {
               this.notify(error.message, "error");
               this.preloader= false
            });

            this.preloader= false
     }
  }
}
</script>

<style>
  @import '@/assets/css/paper/css/bootstrap.min.css';
  @import '@/assets/css/paper/css/paper-dashboard.css';
  @import '@/assets/argon/vendor/nucleo/css/nucleo.css';
  @import '@/assets/argon/css/argon.css';
  @import '@/assets/scss/checkout.sass';
  /* @import '@/assets/css/styles.css'; */
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css';
  @import 'https://fonts.googleapis.com/css2?family=Montserrat&display=swap';
</style>
