import Router from './Router'

export default Router

const router = new Router()

export { router }

/**
 * Layout component
 *
 * @param {String} layout Layout name
 * @param {Object} children Childrens
 * @param {String} path Path url
 * @returns {Object} Layout component
 */
export function layout (layout = 'Default', path, children) {
  return router.layout(layout, path, children)
}

/**
 * Get view route
 *
 * @param {String} name View name
 * @param {Object} component Component
 * @param {String} path Route path
 * @param {Boolean} auth Authorize requered
 * @param {Object} meta Metadata
 * @param {Object} props Props
 *
 * @returns {Object}
 */
export function component (name, path = '', component, auth = true, meta = {}) {
  return router.component(name, path, component, auth, meta)
}

/**
 * Create a new Route View
 * @function routeView
 * @public
 * @since 2.0.1
 * @author Brayan Rincon <hi@bracodev.com>
 *
 * @param {String} name Route name
 * @param {String} path Route path
 * @param {String} view Component and attributes
 * @param {Object} meta Adicional metadata
 * @param {Object} props Props
 *
 * @returns {Object} Route View
 */
export function view (name, path, view, meta = {}, props = {}) {
  return router.view(name, path, view, meta, props)
}

export function error (...args) {
  return router.error(...args)
}

export function abort (...args) {
  return router.abort(...args)
}

export function notFound () {
  return router.notFound()
}
export function notFoundInEviroment (...args) {
  return router.notFoundInEviroment(...args)
}
