import { CURRENCY, LOCALE, SUPPORT_CURRENCY, SUPPORT_LOCALES } from './locale'

import COLORS from './colors'
import CRUD from './crud'
import HTTP from './http'

const CONFIG = {
  name: process.env.VUE_APP_TITLE || 'GetLimpio',
  description: '',

  version: '',

/*
  appURL: process.env.VUE_APP_APP_URL || 'http://localhost:8080',
  apiURL: process.env.VUE_APP_API_URL || 'https://limpio.test',
  apiPrefix: process.env.VUE_APP_API_PREFIX || 'api',
  apiVersion: process.env.VUE_APP_API_VERSION || 'v2',
  stripe_publishable_key : process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY || 'pk_test_51LT3RBCEsPj0xXiq601qqqoySGnPi01MCjIxYHz4ywMnQqpa2gbm85V05ESR3Mt5pT1GPgH12RBSUfXxenlAutIE00bDyoIcxH',
  */ 

  appURL: 'https://booking.getlimpio.co',
  apiURL: 'https://app.getlimpio.co',
  apiPrefix: 'api',
  apiVersion: 'v2',
  stripe_publishable_key: 'pk_test_7AcQ6UJI4NUGClwY9vH7MCP1',

  cache: {
    name: process.env.VUE_APP_CACHE_NAME || 'storage-app',
    time: process.env.VUE_APP_CACHE_TIME || 30000
  },

  locale: {
    currency: CURRENCY,
    supported: SUPPORT_LOCALES,
    locale: LOCALE,
    lang: LOCALE,
    fallbackLocale: LOCALE,
    supportedCurrency: SUPPORT_CURRENCY
  },

  crud: CRUD,

  http: HTTP,

  colors: COLORS
}


export default CONFIG