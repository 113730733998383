import CustomersService from "@/services/CustomersService";
import Vue from 'vue'

const state = {
  loading: {
    login: false,
    forgot: false,
    register: false
  },
  auth: false,
  user: null,
  service: new CustomersService(),
};

const getters = {
  auth: (state) => {
    return state.auth;
  },
  user: (state) => {
    return state.user;
  },
  loadingLogin: (state) => {
    return state.loading.login;
  },

  loadingRegister: (state) => {
    return state.loading.register;
  },
  loadingForgot: (state) => {
    return state.loading.forgot;
  },
};

const actions = {
  auth (context) {
    const data = context.state.service.auth()
    if (data) {
      context.commit('LOGIN', data)
    } else {
      context.commit('LOGOUT')
    }
  },

  async login(context, credencials) {
    context.commit('REQUESTED', 'login')
    return new Promise((resolve, reject) => {
      context.state.service
        .login(credencials)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally( () => context.commit('RESPONSED', 'login'));
    })
    .then(() => context.commit('RESPONSED', 'login'));
  },

  async logout(context) {
    return new Promise((resolve, reject) => {
      context.state.service
      .logout()
      .then((response) => {
        context.commit("LOGOUT", response);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    })
  },

  async forgot(context, email) {
    context.commit('REQUESTED', 'forgot')
    return new Promise((resolve, reject) => {
      context.state.service
      .forgotPassword(email)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    })
    //.then(() => context.commit('RESPONSED', 'forgot'));
  },

  async registercustomer(context, data) {
    context.commit('REQUESTED', 'registercustomer')
    return new Promise((resolve, reject) => {
      context.state.service
      .registercustomer(data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    })
    //.then(() => context.commit('RESPONSED', 'registercustomer'));
  },


   async resetpassword(context, data) {
    context.commit('REQUESTED', 'resetpassword')
    return new Promise((resolve, reject) => {
      context.state.service
      .resetpassword(data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    })
    //.then(() => context.commit('RESPONSED', 'registercustomer'));
  },

  async updateProfile(context, data) {
    context.commit("LOADING", true);
    await context.state.service
      .updateProfile(data)
      .then((response) => {
        context.commit("SET_USER", data);
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      })
      .finally(() => {
        context.commit("LOADING", false);
      });
  },

  async updatePassword(context, data) {
    context.commit("LOADING", true);
    await context.state.service
      .updatePassword(data)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      })
      .finally(() => {
        context.commit("LOADING", false);
      });
  },


   async saveorder(context, data) {
    context.commit('REQUESTED', 'saveorder')
    return new Promise((resolve, reject) => {
      context.state.service
      .saveorder(data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    })
    //.then(() => context.commit('RESPONSED', 'saveorder'));
  },


  async getapp(context, data) {
    context.commit('REQUESTED', 'getapp')
    return new Promise((resolve, reject) => {
      context.state.service
      .getapp(data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    })
    //.then(() => context.commit('RESPONSED', 'saveorder'));
  },

  async savepaymentmethod(context, data) {
    context.commit('REQUESTED', 'savepaymentmethod')
    return new Promise((resolve, reject) => {
      context.state.service
      .savepaymentmethod(data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    })
    //.then(() => context.commit('RESPONSED', 'saveorder'));
  },

  async webhoock(context, data) {
    context.commit('REQUESTED', 'webhoock')
    return new Promise((resolve, reject) => {
      context.state.service
      .webhoock(data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    })
    //.then(() => context.commit('RESPONSED', 'saveorder'));
  },

   async getdata(context, data) {
    context.commit('REQUESTED', 'getdata')
    return new Promise((resolve, reject) => {
      context.state.service
      .getdata(data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    })
    //.then(() => context.commit('RESPONSED', 'saveorder'));
  },

   async updateclient(context, data) {
    context.commit('REQUESTED', 'updateclient')
    return new Promise((resolve, reject) => {
      context.state.service
      .updateclient(data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    })
    //.then(() => context.commit('RESPONSED', 'saveorder'));
  },


   async validatesubdomain(context, data) {
    context.commit('REQUESTED', 'validatesubdomain')
    return new Promise((resolve, reject) => {
      context.state.service
      .validatesubdomain(data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    })
    //.then(() => context.commit('RESPONSED', 'saveorder'));
  },


};

const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  LOGIN(state, user) {
    state.auth = true;
    state.user = user;
  },
  LOGOUT(state) {
    state.auth = false;
    state.user = null;
  },
  REQUESTED(state, loading) {
    Vue.set(state.loading, loading, true)
  },
  RESPONSED(state, loading) {
    Vue.set(state.loading, loading, false)
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
