const currency = require('currency-formatter')

export default currency

/**
 *
 * @param {String} currency
 * @returns
 * {
 *   code: 'USD',
 *   symbol: '$',
 *   thousandsSeparator: ',',
 *   decimalSeparator: '.',
 *   symbolOnLeft: true,
 *   spaceBetweenAmountAndSymbol: false,
 *   decimalDigits: 2
 * }
 */
export function FindCurrency (currency) {
  const c = require('currency-formatter')
  return c.findCurrency(currency)
}
