'use strict'

const stub = require('./stub')
let ls = 'localStorage' in global && global.localStorage ? global.localStorage : stub

export default class LocalStorage {
  static get (key) {
    return JSON.parse(ls.getItem(key))
  }

  static set (key, value) {
    try {
      ls.setItem(key, JSON.stringify(value))
      return true
    } catch (e) {
      return false
    }
  }

  static remove (key) {
    return ls.removeItem(key)
  }

  static clear () {
    return ls.clear()
  }

  static backend (store) {
    store && (ls = store)

    return ls
  }
}
