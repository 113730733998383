import CONFIG from "@/config";
import Vue from "vue";
import VueI18n from "vue-i18n";
import de from "@/locales/de.json";
import en from "@/locales/en.json";
import es from "@/locales/es.json";
import { LocalStorage as ls } from "@/core/LocalStorage";

Vue.use(VueI18n);

const storeLS = ls.get(CONFIG.cache.name);

let lang = "";

if (storeLS) {
  lang = storeLS.lang;
} else {
  lang = CONFIG.locale.locale;
}

export const SUPPORT_LOCALES = CONFIG.locale.supported;

export const LOCALE = lang;console.log(LOCALE);

export const FALLBACK_LOCALE = CONFIG.locale.fallbackLocale;

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = [{ en: en }, { es: es }, { de: de }];
  const messages = {};
  locales.forEach((lang) => {
    const key = Object.keys(lang);
    messages[key] = lang[key];
  });
  return messages;
}

export const TRANSLATES = loadLocaleMessages();

export default new VueI18n({
  locale: LOCALE,
  fallbackLocale: FALLBACK_LOCALE,
  messages: TRANSLATES,
});
