
export const MODE_LIST = 0 // View mode

export const MODE_VIEW = 1 // View mode

export const MODE_EDIT = 2 // Edit mode

export const MODE_CREATE = 3 // Create mode;

/**
 * CRUD Modes
 * @since 2.0.0
 * @constant
 * @author Brayan Rincon <hi@bracodev.com>
 */
const MODES = {
  /**
   * @property {String|Number} default Defualt mode in module
   * @readonly
   */
  defult: 'default',

  /**
   * @property {String|Number} view View mode
   * @readonly
   */
  view: 'view',

  /**
   * @property {String|Number} edit Edit mode
   * @readonly
   */
  edit: 'edit',

  /**
   * @property {String|Number} create Create mode
   * @readonly
   */
  create: 'create'
}

/**
 * Status actions CRUD
 * @since 1.0.0
 * @constant
 * @author Brayan Rincon <hi@bracodev.com>
 */
const STATUS = {
  /**
   * @property {String|Number} ready Ready
   * @readonly
   */
  ready: 1,

  /**
   * @property {String|Number} processing Processing action
   * @readonly
   */
  processing: 2,

  /**
   * @property {String|Number} success Success
   * @readonly
   */
  success: 3,

  /**
   * @property {String|Number} failed Failed
   * @readonly
   */
  failed: 4,

  /**
   * @property {String|Number} edited changed data
   * @readonly
   */
  edited: 5,

  /**
   * @property {String|Number} change changed data
   * @readonly
   */
  change: 5
}

/**
 * Store States
 * @since 2.0.0
 * @constant
 * @author Brayan Rincon <hi@bracodev.com>
 */
const LOADINGS = {

  /**
   * @property {Boolean} getting Getting records
   * @readonly
   */
  get getting () { return false },

  /**
   * @property {Boolean} getted Records obtained
   * @readonly
   */
  get getted () { return false },

  /**
   * @property {Boolean} creating Creating record(s)
   * @readonly
   */
  get creating () { return false },

  /**
   * @property {Boolean} created Record(s) created
   * @readonly
   */
  get created () { return false },

  /**
   * @property {Boolean} updating Updating record(s)
   * @readonly
   */
  get updating () { return false },

  /**
   * @property {Boolean} updated Record(s) updated
   * @readonly
   */
  get updated () { return false },

  /**
   * @property {Boolean} updated Deleting Record(s)
   * @readonly
   */
  get deleting () { return false },

  /**
   * @property {Boolean} updated Record(s) deleted
   * @readonly
   */
  get deleted () { return false },

  /**
   * @property {Boolean} restoring Restoring fecord(s) from trash
   * @readonly
   */
  get restoring () { return false },

  /**
   * @property {Boolean} restored Record(s) restored from trash
   * @readonly
   */
  get restored () { return false },

  /**
   * @property {Boolean} validating Validating data
   * @readonly
   */
  get validating () { return false },

  /**
   * @property {Boolean} other Others accions
   * @readonly
   */
  get other () { return false },

  /**
   * @property {Boolean} trashGetting Getting record from trash
   * @readonly
   */
  get trashGetting () { return false },

  /**
   * @property {Boolean} trashGetted Records obtained from the trash
   * @readonly
   */
  get trashGetted () { return false },

  /**
   * @property {Boolean} trashDeleting Deleting record(s) from the trash
   * @readonly
   */
  get trashDeleting () { return false },

  /**
   * @property {Boolean} trashDeleted Record(s) deleted from trash
   * @readonly
   */
  get trashDeleted () { return false }

}

const CRUD = {
  modes: Object.assign({}, MODES),
  status: Object.assign({}, STATUS),
  loadings: Object.assign({}, LOADINGS)
}

export default CRUD
