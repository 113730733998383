<template>
  <v-item-group v-bind="$attrs">
    <v-container class="pl-7">
      <v-row dense>
        <v-col v-for="(item, index) in items" :key="index">
          <ChoiseItemGroup :item="item" @change="change"></ChoiseItemGroup>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>
<script>
export default {
  name: "ItemGroup",
  data: () => {
    return {
      val: null
    };
  },
  model: {
      prop: 'value',
      event: 'change'
  },
  props: {
    value: Number,
    items: Array,
    height: {
      type: [String, Number],
      default: "50",
    },
  },
  methods: {
    change (e) {
      this.val = e
      this.$emit('change', e)
    }
  }
};
</script>
