import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store'

Vue.use(VueRouter)

//console.log(routes)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('users/auth')

  const requiresAuth = to.matched.some((record) => record.meta.requireAuth)
  const authenticate = store.getters['users/auth']
  const loginQuery = { path: '/login', query: { redirect: to.fullPath } }

  if (requiresAuth === true && authenticate === false) {
    next(loginQuery)
  } else {
    next()
  }
})

export default router
