import 'vue-toastification/dist/index.css'

import Toast, { POSITION, TYPE } from 'vue-toastification'

import Vue from 'vue'

const options = {
  toastDefaults: {
    // ToastOptions object for each type of toast
    [TYPE.ERROR]: {
      timeout: 10000,
      closeButton: true
    },
    [TYPE.SUCCESS]: {
      timeout: 3000,
      hideProgressBar: true
    }
  }
}

Vue.use(Toast, options)

export function notice (message, type = TYPE.DEFAULT, timeout = 5000) {
  const options = {
    type: type,
    position: POSITION.TOP_RIGHT,
    timeout: timeout,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 1,
    showCloseButtonOnHover: true,
    hideProgressBar: false,
    closeButton: 'button',
    icon: true,
    rtl: false
  }
  this.$toast(message, options)
}
